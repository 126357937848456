<template>
  <div>
    <portal to="page-top-title">Category #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'senders-categories-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/senders/categories/${id}`" data-prop="category" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ category.name }}</column-info>
          <column-info title="Group" v-if="category.group">
            <router-link :to="{ name: 'categoryGroups-single', params: { id: category.group.id } }">{{ category.group.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ category.created_at }}</column-info>
          <column-info title="Updated At">{{ category.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="category.active"/>
          </column-info>
          <column-info title="Senders">
            <badge title="Total">{{ category.senders_count_total }}</badge>
            <badge title="Active" color="green">{{ category.senders_count_active }}</badge>
            <badge title="Inactive" color="red">{{ category.senders_count_inactive }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Senders" url="/senders" :url-query="{ category_id: id }" data-prop="senders" loader>
      <template slot="box-tools-right">
        <btn :route="{ name: 'senders-new', query: { category: id } }" size="xs" icon="fa-plus" text>Add New</btn>
      </template>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="sender in senders" :key="`senders-tr-${sender.id}`" :value="sender">
          <td>{{ sender.id }}</td>
          <td>{{ sender.name }}</td>
          <td class="text-center">
            <badge-active :value="sender.active"/>
          </td>
          <td class="text-right">{{ sender.created_at }}</td>
          <td class="text-right">{{ sender.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'senders-edit', params: { id: sender.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'senders-single', params: { id: sender.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'SendersCategoriesSingle',
  metaInfo () {
    return { title: 'Senders | Category #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    Btn,
    Badge,
    BadgeActive
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      category: {},
      senders: []
    }
  }
}
</script>
